import { AppProps } from 'next/app'
import Head from 'next/head'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const App = ({ Component, pageProps }: AppProps) => (
  <>
    <Head>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="format-detection" content="telephone=no" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://betsudai.co.jp" />
      <meta property="og:image" content="https://betsudai.co.jp/img/ogp.jpg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content="https://betsudai.co.jp" />
      <meta name="twitter:image" content="https://betsudai.co.jp/img/ogp.jpg" />
      <link rel="icon" type="image/png" href="/favicon.png" />
      <link rel="apple-touch-icon" href="/img/apple-touch-icon.png" />

    </Head>
    <Component {...pageProps} />
  </>
)

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

export default App